import {
    convertTimeStringToTimestamp,
    isEmpty,
    isInTimeEndByNow,
    SERVICE_INTERVAL_TIME
} from "@/utils/operation";

const STUDENT_TOKEN_NAME = 'STUDENT_TOKEN'  // token （学生）
const LATEST_SERVICE_TIME_SESSION = 'SERVICE_TIME_LATEST' // 最新的服务时间
export const SERVICE_STUDENT_ID = 'STUDENT_ID' // 当前用户的id
export const STUDENT_LOGIN_NAME = 'STUDENT_LOGIN_NAME' // 学生登陆id (g201, g392); 教师 tea01
const STUDENT_GROUPID = 'STUDENT_GROUP_ID' // group id
const ACTIVITY_ID = 'CURRENT_ACTIVITY_ID' // activity id

export const STU_INTERACT_TIME_TO_UPLOAD = 1*1000 // 1s (1000ms)

// stage 1 (brainstorm) 用户输入的信息类型
export const INFO_CATEGORY_BRAINSTORM = [
    { key: '1', value: '实验假设', color: '#82B366' },
    { key: '2', value: '实验计划', color: '#D79B00' },
    { key: '0', value: '其他想法', color: '#6C8EBF' },
    { key: '3', value: '实验实施', color: '#8066b3' },
    { key: '4', value: '实验解释', color: '#d74100' },
];

/**
 * 与INFO_CATEGORY_BRAINSTORM相对应的，查找属性的方法
 * @param key = 1， 2， 0
 * @param attri， ‘value’, 'color'
 * @returns {string}
 */
export function getAttributebyKey(key, attri) {
    const findItem = INFO_CATEGORY_BRAINSTORM.find(item => item.key === key);
    return findItem[attri]
}

export const saveStudentInfo = (tokenObj, studentId, studentLoginName, groupId, activityId) => {
    localStorage.setItem(STUDENT_TOKEN_NAME, JSON.stringify(tokenObj)) // token值
    localStorage.setItem(SERVICE_STUDENT_ID, JSON.stringify(studentId)) // 学生标识
    localStorage.setItem(STUDENT_LOGIN_NAME, JSON.stringify(studentLoginName)) // 学生登陆id
    localStorage.setItem(STUDENT_GROUPID, JSON.stringify(groupId)) // groupId
    localStorage.setItem(ACTIVITY_ID, JSON.stringify(activityId)) // groupId
}

export const getStudentToken = () => {
    return JSON.parse(localStorage.getItem(STUDENT_TOKEN_NAME))
}

// 获取学生登陆id
export const getStudentLoginName = () => {
    return JSON.parse(localStorage.getItem(STUDENT_LOGIN_NAME))
}

export const delStudentToken = () => {
    localStorage.removeItem(SERVICE_STUDENT_ID)
    localStorage.removeItem(STUDENT_TOKEN_NAME)
    localStorage.removeItem(STUDENT_LOGIN_NAME)
    localStorage.removeItem(STUDENT_GROUPID)
    localStorage.removeItem(ACTIVITY_ID)
}

export const getStudentIdFromStorage = () => {
    return parseInt(JSON.parse(localStorage.getItem(SERVICE_STUDENT_ID)))
}

export const getGroupIdFromStorage = () => {
    return parseInt(JSON.parse(localStorage.getItem(STUDENT_GROUPID)))
}

export const getActivityIdFromStorage = () => {
    return parseInt(JSON.parse(localStorage.getItem(ACTIVITY_ID)))
}


// 获取颜色值
export function getColorByIndex(index) {
    const colorList = ['#FFF6C8', '#E0FFC8', '#C8FFF2', '#C8F2FF',
        '#C8DBFF', '#E0C8FF', '#FFC8C8', '#FFE6C8']

    const _len = colorList.length

    if (isEmpty(index) || index < 0 || isNaN(index))
        return colorList[0];

    const _colorIndex = index % _len;

    return colorList[_colorIndex];
}

/**
 * 根据id判断是否为当前用户
 * @param userId
 */
export function isCurrentUser(userId) {
    let userIdFromStorage = getStudentIdFromStorage()
    let currentUserId = parseInt(userId)

    return userIdFromStorage === currentUserId
}

/**
 * 若url中参数丢失，则采用storage
 * @param urlParams
 * @returns {number[]}
 */
export function getPageInitParams(urlParams) {
    let groupid = urlParams.group
    if (isEmpty(groupid)) {
        groupid = getGroupIdFromStorage()
    }

    let activityid = urlParams.id

    if (isEmpty(activityid)) {
        activityid = getActivityIdFromStorage()
    }

    let stageid = urlParams.stage
    if (isEmpty(stageid))
        stageid = 1

    return {
        groupid: groupid,
        activityid: activityid,
        stageid: stageid
    }
}

/**
 * 根据字符串判断用户角色
 * userRole: 用户loginname
 * teacher: tea-
 * student: g-; s-
 */
export function adjustUserRole(userRole) {
    if (isEmpty(userRole)) return 'teacher';
    const role = userRole.toLowerCase();

    if (role.startsWith('tea')) {
        return 'teacher';
    } else {
        return 'student';
    }
}

/**
 * 找本组用户最后一次操作时间，用于判断 是否需要刷新当前页面的所有接口
 * return null
 * @param stuList
 */
export function updateServiceTime(stuList) {
    const currentStuId = getStudentIdFromStorage() // 当前用户信息
    // const currentTime = new Date().getTime() // 当前时间
    const lastTime = _getLastServiceTime(stuList, currentStuId)
    // console.log('用户列表，', stuList, '最新服务时间，', lastTime, new Date(lastTime))
    // let isNeedUpdate = isInTimeEndByNow(lastTime, timeInterval*10) // 判断是否在需要更新的时间范围内 (循环更新时间*10，均需要刷新数据）

    // let updateParam = LATEST_SERVICE_TIME_SESSION // 更新指示
    // const lastUpdateTime = JSON.parse(sessionStorage.getItem(updateParam));

    // 范围内，更新
    sessionStorage.setItem(LATEST_SERVICE_TIME_SESSION, JSON.stringify(lastTime));
    // if (isNeedUpdate) {
    //     sessionStorage.setItem(LATEST_SERVICE_TIME_SESSION, JSON.stringify(lastTime));
    // } else { // 范围外，不用更新
    //     sessionStorage.removeItem(LATEST_SERVICE_TIME_SESSION)
    // }

    // 查找用户最后的操作时间 （整个小组，最后操作时间）(已剔除当前用户的操作）
    function _getLastServiceTime(list, currentStuId) {
       // console.log(list, currentStuId)
        return list.reduce((lastTime, student) => {
            if (!isEmpty(student.updatetime) && parseInt(student.studentid) !== currentStuId) {
                let updateTime = convertTimeStringToTimestamp(student.updatetime);
                // console.log('处理用户时间，', student.updatetime, updateTime)
                return Math.max(lastTime, updateTime);
            }
            return lastTime;
        }, 0)
    }
}


/**
 * 根据sessionStorage信息，更新接口请求
 * @param fn function 接口请求
 * @param timeInterval 接口请求的时间间隔
 * 参考：https://blog.csdn.net/hyupeng1006/article/details/104037647
 */
export function updateServiceByInterval(fn, timeInterval = SERVICE_INTERVAL_TIME) {

    // 循环调用服务
    let  timeoutId;

    console.log('-------开始判断是否需要更新用户服务-------------')

    function scheduleNextExecution() {
        timeoutId = setTimeout(async () => {
            let isNeedUpdate = _isNeedUpdateSessionVal()

            // console.log('是否需要更新服务，', isNeedUpdate)

            if (isNeedUpdate) {
                // 需要循环更新的主体函数存在，并调用
                try {
                    await fn()
                } catch (error) {
                    console.error('服务请求更新时，出错啦！')
                }
            }
            scheduleNextExecution()
        }, timeInterval)
    }

    // 首次执行服务更新
    scheduleNextExecution()

    function clearScheduledExecution() {
        clearTimeout(timeoutId)
    }

    // 根据本地sessionStorage判断是否需要更新
    function _isNeedUpdateSessionVal() {
        let sessionVal = sessionStorage.getItem(LATEST_SERVICE_TIME_SESSION);
        // console.log('上次服务时间：', sessionVal, convertStringToTime(JSON.parse(sessionVal)))
        // console.log(`上次服务时间${sessionVal}, 在更新时间范围内：${isInTimeEndByNow(JSON.parse(sessionVal), 10 * 1000)}`)
        return !isEmpty(sessionVal) && isInTimeEndByNow(JSON.parse(sessionVal), 10 * 1000); // 10s内的同伴信息，则更新
    }

    return clearScheduledExecution
}

// 根据studentid分组信息
export function groupDataByStudentid(dataList) {
    return dataList.reduce((acc, item) => {
        const {studentid} = item;
        if (!acc[studentid]) {
            acc[studentid] = [];
        }
        acc[studentid].push(item);
        return acc;
    }, {})
}

// 将当前学生的信息放置首位
// 跟groupDataByStudentid联动使用
// 有问题，还不指导怎么把当前用户值放第一位
export function setCurrentStudentFirst(dataList) {
    const currentStudentID = getStudentIdFromStorage()

    const prioritizedGroupData = {};
    let tempStr = ''

    console.log(currentStudentID, dataList[currentStudentID])

    // If data for currentStudentID exists, add it first
    if (dataList[currentStudentID]) {
        tempStr = currentStudentID.toString()
        prioritizedGroupData[tempStr] = dataList[currentStudentID];
    }

    console.log(prioritizedGroupData)
    prioritizedGroupData['255'] = [{}]

    // Add the rest of the data
    for (const studentid in dataList) {
        let stuid = studentid
        console.log(stuid, typeof stuid, currentStudentID, typeof currentStudentID, stuid !== currentStudentID)
        if (stuid !== currentStudentID) {
            tempStr = stuid.toString()
            prioritizedGroupData[tempStr] = dataList[studentid];
        }
    }

    console.log(prioritizedGroupData)

    return prioritizedGroupData;
}
